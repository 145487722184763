<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/course/top-img-2.png" alt="" />
    </div>
    <div class="course-box">
      <div class="course-nav">
        <div :class="{ 'course-nav-list': true, tabActive: tabActive == cateDataItem.cateId }"
          v-for="cateDataItem in cateData" :key="cateDataItem.cateId" @click="tabChange(cateDataItem.cateId)">
          {{ cateDataItem.cateName }}</div>
      </div>
      <!-- 课程主体部分 -->
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="learnCourse" v-infinite-scroll="load" style="overflow:auto;min-height: 30rem;width:100%">
          <div class="learnCourse-list" v-for="cataDataListItem in cataDataList" :key="cataDataListItem.courseId">
            <div class="learnCourse-list-img">
              <el-image v-bind:src="cataDataListItem.coverImg" scroll-container=".learnCourse" lazy />
            </div>
            <div class="learnCourse-list-title">{{ cataDataListItem.courseTitle }}</div>
            <div class="learnCourse-list-text">
              <div class="hot-class-icon">
                <img src="../assets/course/hot-icon.png" alt="">
                <div class="hot-class-num">{{ cataDataListItem.studyNum }}</div>
              </div>
            </div>
          </div>
          <div class="no-content" v-if="!cataDataList.length">
            <img src="../assets/home/no-content.png" alt="">
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { post, get } from "../utils/xajax";
import request from "../utils/request";
export default {
  data () {
    return {
      tabActive: 0,
      cataDataList: [],
      cateData: [],
      cateId: 0,
      page: 1,
      perPage: 8,
      total: 0,
      loading: false,
      finished: true,
    };

  },
  methods: {
    load () {
      // if(this.page == -1) return;
      // this.page++;
      // this.getCourseList();
    },
    //导航列表
    getCourseCate () {
      let data = {};
      request({
        url: "/course/cate",
        method: "post",
        data,
      }).then((res) => {
        this.cateData = res.data;
        this.cateData.unshift({ cateId: 0, cateName: '全部' })
      });
    },
    //课程列表
    getCourseList (cateId) {
      let data = {
        cateId: this.cateId,
        pageNum: this.page,
        // perPage: this.perPage,
      };
      request({
        url: "/course/lists",
        method: "post",
        data: data,
      }).then((res) => {
        if (res.data.lists.length == 0) {
          this.finished = true;
        } else {
          this.finished = false
        }
        if (this.page == 1) {
          this.cataDataList = res.data.lists;
        } else {
          this.cataDataList = this.cataDataList.concat(res.data.lists);
        }
        this.total = res.data.count;
      }).finally(() => {
        this.loading = false;
      })
    },
    tabChange (cateId) {
      this.cateId = cateId
      this.page = 1
      this.cataDataList = [];
      this.tabActive = cateId
      this.getCourseList(cateId)

    },
    toClasses (e) {
      window.open(e, '_blank');
    },
    onLoad () {
      this.page++
      this.getCourseList()
    },
  },
  computed: {},
  mounted () {
    this.getCourseCate();
    this.getCourseList();
  },
};
</script>

<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0rem;
  padding: 0rem;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

.topImg {
  display: flex;
  position: relative;
  margin-top: 2rem;
}

.topImg>img {
  width: 100%;
  height: 7.1rem;
}

.topImg-headline {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 1.2rem;
}

.topImg-title {
  font-size: 1.8rem;
  font-family: AlibabaPuHuiTiB;
  color: #ffffff;
}

.course-box {
  padding: 0 1rem;
}

.banner {
  margin-top: 0.2rem;
}

.banner>img {
  width: 100%;
}

.course-nav {
  display: flex;
  margin-top: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.1rem;
  margin-bottom: 1rem;
  padding-left: 0.1rem;
  // 溢出部分剪切
  overflow: hidden;
  // 设置横向滚动
  overflow-x: scroll;
  // 禁止纵向滚动
  overflow-y: hidden;
  // 文本平铺
  text-align: justify;
  box-sizing: border-box;
  // 文本不允许换行
  white-space: nowrap;
}

.course-nav::-webkit-scrollbar {
  display: none;
}

.course-nav-list {
  margin-right: 2.8rem;
  padding: 0 .4rem;
  font-weight: 400;
  color: #3B3D41;
  font-size: 1.6rem;
  border-radius: .7rem;
  cursor: pointer;
}

.tabActive {
  color: #4676F8;
  border-radius: 1.1rem;
  box-shadow: 0 0 0 .05rem #4676F8;
  background: rgba(70, 118, 248, 0.05);
  padding: 0 .5rem;
}

.learnCourse {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: .3rem;
}

.learnCourse::-webkit-scrollbar {
  display: none;
}

.learnCourse-list {
  width: 16.5rem;
  margin-bottom: 1.6rem;
}

.learnCourse-list:last-child {
  padding-bottom: 2.9rem;
}

.learnCourse-list-img>img {
  width: 7.5rem;
  height: 5rem;
  border-radius: .3rem;
}

.learnCourse-list-title {
  padding-top: 1.2rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: #3B3D41;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

.learnCourse-list-text {
  padding-top: .75rem;
  font-weight: 400;
  color: #9B9DA2;
  font-size: 1.1rem;
}

.hot-class-icon {
  display: flex;
}

.hot-class-num {
  width: 8.85rem;
  height: .95rem;
  font-size: 1.1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9B9DA2;
  line-height: .9rem;
}

.hot-class-icon>img {
  width: 1.2rem;
  height: 1.2rem;
}

.no-content {
  text-align: center;
  margin: 2.5rem auto 3rem;
}

.no-content>img {
  // width: 50%;
  // height: 50%;
  height: 30.625rem;
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.el-image {
  display: block;
  min-height: 0.05rem;
}
</style>