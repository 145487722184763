<template>
  <div class="box">
      <div class="topImg">
      <img src="../assets/product/top-img-ruan.png"  alt="" />
      <!-- <div class="topImg-headline">
        <div class="topImg-title">产品-软件</div>
      </div> -->
    </div>
    <!-- 中心内容 -->
    <div class="content-centre">
        <div class="centre-one-text"> 心灵通是基于互联网思维搭建的心理健康咨询与教育一体化平台，通过平台整合行业专家、优质课程和特色服务，为学校搭建精准化心理测评、 
            全程化危机干预、多元化咨询指导和个性化心理教育四大工作平台，致力于解决教育管理部门和学校心理健康工作中部分存在的课程资源不足、 
            筛查精度不高、咨询力量不够和干预过程管理不严的问题，通过闭环管理、过程留痕和数据驱动等手段建设有效降低危机概率，提高学生的心理健康素养。 
            心灵通包括心理测评、访谈管理、心理咨询和心理教育等9个子系统，可独立应用，也可整合起来应用。 
        </div>
        <div class="elTabs">
            <el-tabs v-model="activeName">
                <el-tab-pane label="心理测评系统" name="0" ref="tabItem0">
                    <div>
                        <p class="text">心理测评系统支持定向测试和集体测试两种模式，经过定制量表、发布测评、开展测评、过程监控和结果管理几个步骤完成测评，最终自动生成测评报告。
                            系统根据学校需求不同，提供了标准化量表库和个性化量表管理，学校可以选择标准量表，也可以导入个性化量表或者自定义量表，大大提高了学校开展心理普测的便捷性和准确率。
                            测评系统支持PC端和微信小程序，测评方便快捷。
                        </p>
                        <div class="cepingImg"> <img src="../assets/product/productSoft-one.png" alt=""> </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="心理咨询系统" name="1" ref="tabItem1">
                    <div>
                        <p class="text">
                          系统根据不同的咨询场景提供多种咨询服务形式，保障同学们的咨询体验，具体形式包括：针对简单的咨询问题，通过智能咨询和机器人的方式解答；针对稍微复杂的常见问题，可以通过在线专家咨询的方式进行，支持实时和非实时两种模式；针对比较复杂的问题采用线下咨询的模式，支持咨询预约、过程记录、咨询处理和自动归档等功能。系统还提供专家管理和案例管理功能。
                        </p>
                        <div class="cepingImg"> <img src="../assets/product/productSoft-two.png" alt=""> </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="课程教学系统" name="2" ref="tabItem2">
                    <div>
                        <p class="text">
                          课程教学系统主要解决学校的心理课程教学问题，涵盖多种教学模式，一种是线上教学模式，通过提供精品在线课程或者个性化定制校本课程，实现学生自助线上学习，支持在线视频、作业、讨论和考试；一种是混合式教学模式，通过平台有效支撑教师进行教学改革，完成课前自主学习、课中讨论交互、课后复习强化的教学组织；一种是线下教学模式，支持教师在课堂进行优质资源的分发讨论。系统还提供一些音视频资源，作为学生自助学习使用。
                        </p>
                        <div class="cepingImg"> <img src="../assets/product/productSoft-three.png" alt=""> </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="活动管理系统" name="3" ref="tabItem3">
                    <div>
                        <p class="text">
                          活动管理是一个满足心理健康教育活动全流程管理的系统，具体包括活动发布、活动报名、过程管理和活动评价。活动形式具体包括主题教育、自主学习、团体咨询活动、沙龙等。
                        </p>
                        <div class="cepingImg"> <img src="../assets/product/productSoft-four.png" alt=""> </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="访谈管理系统" name="4" ref="tabItem4">
                    <div>
                        <p class="text">
                          测评产生的名单将进入访谈环节，由各院系通过访谈进一步确认学生的情况。访谈管理系统提供了任务分配、访谈管理、过程留痕等功能，有效地将院系负责人、辅导员组织到访谈工作中来，做到分工明确、过程可查、结果可控。访谈结果通过标签方式进一步分组，产生待咨询清单，进入咨询环节。访谈管理系统通过信息化流程的梳理打造实现了访谈名单的规范化跟进管理，并做到业务留痕。
                        </p>
                        <div class="cepingImg"> <img src="../assets/product/productSoft-five.png" alt=""> </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="重点关注系统" name="5" ref="tabItem5">
                    <div>
                        <p class="text">
                          重点关注系统是一个日常工作系统，用于院系辅导员、心理委员在日常生活学习过程中上报一些异常情况，比如行为异常、突发事件涉及到的学生，进入重点关注系统，重点关注分为校级关注和院级关注。辅导员和心理委员要将重点关注学生的一些谈话记录、行为记录、重要事项、处理办法及时上传系统，做到一生一策，过程可控。
                        </p>
                        <div class="cepingImg"> <img src="../assets/product/productSoft-six.png" alt=""> </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="干预管理系统" name="6" ref="tabItem6">
                    <div>
                        <p class="text">
                          干预管理系统是对需要干预的学生进行全程管理的信息化支撑工具，系统提供了全流程管理功能，方便地实现干预清单的及时分发、任务管理和结果管理。
                        </p>
                        <div class="cepingImg"> <img src="../assets/product/productSoft-seven.png" alt=""> </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="门户管理系统" name="7" ref="tabItem7">
                    <div>
                        <p class="text">
                          门户管理系统定位于展现学校心理健康咨询与教育工作的相关服务和成果，具体包括宣传心理健康教育相关政策，展示中心具体工作，及时发布组织活动，普及心理健康教育知识。满足学校建设融思想性、知识性、趣味性、服务性于一体的心理健康教育网站的需求，促进学生关注心理健康、助人自助、快乐成长，营造健康和谐、积极向上的校园氛围。门户管理系统包括栏目管理、内容管理等相关功能，支持多种框架，支持个性化定制。
                        </p>
                        <div class="cepingImg"> <img src="../assets/product/productSoft-eight.png" alt=""> </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="档案管理系统" name="8" ref="tabItem8">
                    <div>
                        <p class="text">
                          通过全流程数据留痕，平台完整记录了学生在评测、课程、活动、咨询中的表现，完整记录了教师在平台进行教学、咨询和服务的完整表现，这样就可以生成学生和教师画像，以及学生和教师成长档案，实现“一生一档案，一师一档案”。还可以基于运行数据，生成《大学生心理健康咨询与教育发展研究》等分析报告，邀约专家给予诊断建议，为建设示范性大学生心理健康咨询中心提供指导建议。
                        </p>
                        <div class="cepingImg"> <img src="../assets/product/productSoft-nine.png" alt=""> </div>
                    </div>
                </el-tab-pane>
             </el-tabs>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            activeName: '0',
            tabsValue:''
        }
    },
    methods:{
      
    },
    mounted(){
      console.log(this.$route)
      let tabIndex = this.$route.params.index;
      if (tabIndex) {
          this.$nextTick(()=>{
            document.querySelector(`#tab-${tabIndex}`).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center'})
            // 改变当前选中
            setTimeout(()=>{
              this.activeName = tabIndex
            }, 500)
              // this.$refs['plan-titleBar'].scrollIntoView({ behavior: 'smooth', block: 'start'})
          })
      }
    }
}
</script>

<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0rem;
  padding: 0rem;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
.topImg {
  display: flex;
  position: relative;
  margin-top: 2rem;
}
.topImg > img {
  width: 100%;
  height: 7.1rem;
}
.topImg-headline {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 1.2rem;
}
.topImg-title {
  font-size: 1.8rem;
  font-family: AlibabaPuHuiTiB;
  color: #ffffff;
}
.topImg-text {
  font-size: 1.6rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #ffffff;
}
.content-centre {
  padding: 0rem 1.2rem;
}
.centre-one-text{
    font-size: 1.4rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3B3D41;
    line-height: 2.4rem;
    margin: 2rem 0rem 2rem;
    text-align: justify;
}

.text{
    width: 34.8rem;
    // height: 19.2rem;
    font-size: 1.4rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3B3D41;
    line-height: 2.4rem;
}
.cepingImg>img{
    width: 35.1rem;
    margin: 1.6rem 0rem 2.8rem;
}
/deep/.el-tabs__nav{
  transform: translateX(0) !important;
}
// /deep/.el-tabs__nav::-webkit-scrollbar{
//   display: none;
// }
/deep/.el-tabs__nav-wrap {
  overflow: hidden;
  margin-bottom: -.05rem;
  position: relative;
}
/deep/.el-tabs__active-bar {
  margin-bottom: -1.1rem;
}
/deep/ .el-tabs__nav-scroll {
  margin-top: 2rem;
  justify-content: space-around;
  padding-bottom: 1.1rem;
  overflow-x: scroll;
}
/deep/.el-tabs__nav-scroll::-webkit-scrollbar{
  display: none;
}
/deep/.el-icon-arrow-left::before{
  display: none;
}
/deep/.el-icon-arrow-right::before{
  display: none;
}
/deep/.el-tabs__item {
  font-size: 1.6rem;
}
/deep/.el-carousel__button {
  background: #888;
}
/deep/.el-tabs__active-bar {
  background-color: #0156ff;
  width: 3.3rem !important;
  margin-left: 3.3rem;
}
/deep/.el-tabs__item.is-active {
  color: #0156ff;
}
/deep/.el-tabs__item:hover {
  color: #0156ff;
}
</style>