import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vant from 'vant';
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'amfe-flexible';
import BaiduMap from 'vue-baidu-map';
import common from '../common.js';

Vue.use(common);

import vueMiniPlayer from 'vue-mini-player';
import 'vue-mini-player/lib/vue-mini-player.css';
Vue.use(vueMiniPlayer);

Vue.use(BaiduMap, {
  ak: 'uhfKv2OqL5TOD9aLCqSbCTWgSaz0hGUj',
});

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(vant);

// 页面跳转到另一页面的最顶部
router.afterEach((to, from, next) => {
  document.documentElement.scrollTop = document.body.scrollTop = 0;
  window.scrollTo(0, 0);
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
