<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/scheme/top-img4.png" alt="" />
      <!-- <div class="topImg-headline">
        <div class="topImg-title">方案-教师培训</div>
      </div> -->
    </div>
    <!-- 中心内容 -->
    <div class="content-centre">
      <div class="teacher-centre-one">
        <img src="../assets/scheme/teacher-one.png" alt="" />
        <p class="teacher-text">根据高等教育、中等职业教育和基础教育的特点，针对管理部门、咨询师、课程授课教师、辅导员等角色提供专业的培训课程体系，激活教师团队，提升本土化的心理危机识别与干预能力，培训分为线上培训和线下培训两种模式，支持为学校定制个性化培训方案。</p>
      </div>
      <div class="teacher-box">
        <div class="teacher-left-box">
          <div class="teacher-left-boxImg"><img src="../assets/scheme/teacher-left-icon.png" alt="" /></div>
          <p class="teacher-title">基本素养培训</p>
          <div class="teacher-list">
            <ul>
              <li>心理危机筛查与干预相关政策</li>
              <li>基础教育常见心理问题及处理办法</li>
              <li>心理危机筛查和干预过程中的注意事项</li>
            </ul>
          </div>
        </div>
        <div class="teacher-right-box">
          <div class="teacher-left-boxImg"><img src="../assets/scheme/teacher-right-icon.png" alt="" /></div>
          <p class="teacher-title">风险筛查技能培养</p>
          <div class="teacher-list">
            <ul>
              <li>基础教育心理危机筛查与干预主要流程和工作内容</li>
              <li>学生一对一访谈和风险识别培训</li>
              <li>心理咨询常见问题及方法</li>
            </ul>
          </div>
        </div>
        <div class="teacher-right-box">
          <div class="teacher-left-boxImg"><img src="../assets/scheme/teacher-right-icon.png" alt="" /></div>
          <p class="teacher-title">管理和技术培训</p>
          <div class="teacher-list">
            <ul>
              <li>心理危机筛查与干预整体流程培训</li>
              <li>心理危机筛查与干预管理员培训</li>
              <li>心理测评培训</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0rem;
  padding: 0rem;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
.topImg {
  display: flex;
  position: relative;
  margin-top: 2rem;
}
.topImg > img {
  width: 100%;
  height: 7.1rem;
}
.topImg-headline {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 1.2rem;
}
.topImg-title {
  font-size: 1.8rem;
  font-family: AlibabaPuHuiTiB;
  color: #ffffff;
}
.topImg-text {
  font-size: 1.6rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #ffffff;
}
.content-centre {
  padding: 0rem 1.2rem;
}
.teacher-centre-one > img {
  width: 100%;
  margin: 2.8rem 0rem 1.6rem;
}
.teacher-box {
  width: 100%;
  // display: flex;
  // justify-content: space-between;
  //   margin-bottom: 2.8rem;
  margin-bottom: 3.5rem /* 56/16 */;
}
.teacher-text {
  // width: 35.1rem;
  font-size: 1.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 2.4rem;
  margin-bottom: 2rem;
}

.teacher-left-box,
.teacher-right-box {
  // width: 16rem;
  background: #ffffff;
  // box-shadow: 0.3rem 0.35rem 0.65rem 0rem rgba(1, 86, 255, 0.09);
  box-shadow: 0 2px 12px 0 rgba(1, 86, 255, 0.09);
  border-radius: 0.5rem;
  padding-left: 15px;
}
.teacher-left-boxImg {
  text-align: center;
}
.teacher-left-boxImg > img {
  width: 2.6rem;
  margin-top: 2rem;
}
.teacher-title {
  height: 2.25rem;
  font-size: 1.6rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 2.25rem;
  margin: 0.7rem 0rem 1.2rem;
  text-align: center;
}
.teacher-list {
  padding: 0rem 1.2rem;
  padding-bottom: 2.5rem;
}
.teacher-list ul li {
  font-size: 1.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 1.8rem;
  list-style: disc;
}
.teacher-list ul li::marker {
  color: #0156ff;
}
</style>