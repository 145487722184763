<template>
  <div class="box">
    <!-- 内容图 -->
    <div class="topImg">
      <img src="../assets/home/top-img.png" alt="" />
    </div>
    <!-- <div class="topImg">
          <img src="../assets/home/topImg.png" alt="">
          <div class="topImg-headline">
              <div class="topImg-title">心灵通</div>
              <div class="topImg-text">关注心理健康教育</div>
          </div>
      </div> -->
    <!-- 中心内容 -->
    <div class="center-box">
      <!-- 解决方案 -->
      <div>
        <h1 style="padding-top: 2rem">解决方案</h1>
        <div class="border-bottom"></div>
        <div class="plan-content">
          <div class="plan-content-img" @click="routerPush('/schemeHeight')">
            <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/three.png" alt="" />
            <div class="plan-content-mark">
              <img src="../assets/home/three-top.png" alt="" />
              <p class="plan-content-img-text">高等教育</p>
            </div>
          </div>
          <div class="plan-content-img" @click="routerPush('/schemeBasics')">
            <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/two.png" alt="" />
            <div class="plan-content-mark">
              <img src="../assets/home/two-top.png" alt="" />
              <p class="plan-content-img-text">基础教育</p>
            </div>
          </div>
          <div class="plan-content-img" @click="routerPush('/schemeTrade')">
            <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/one.png" alt="" />
            <div class="plan-content-mark">
              <img src="../assets/home/one-top.png" alt="" />
              <p class="plan-content-img-text">中等职业教育</p>
            </div>
          </div>
          <div class="plan-content-img" @click="routerPush('/schemeTrain')">
            <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/four.png" alt="" />
            <div class="plan-content-mark">
              <img src="../assets/home/four-top.png" alt="" />
              <p class="plan-content-img-text">教师培训</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品体系 -->
      <div class="plan">
        <h1>产品体系</h1>
        <div class="border-bottom"></div>
        <div class="plan-content2">
          <div class="product-box">
            <div class="product-content-list">
              <div class="product-content-list-content">
                <div class="product-content" v-for="item in arrList" :key="item.id">
                  <div class="product-top">
                    <div class="product-title">{{ item.title }}</div>
                    <div class="productImg">
                      <img v-bind:src="item.topImg" alt="" />
                    </div>
                  </div>
                  <div class="product-text">
                    {{ item.info }}
                  </div>
                  <router-link
                    :to="{
                      name: 'productSoft',
                      params: { index: item.index },
                    }"
                    class="seeMore"
                  >
                    了解更多
                    <div style="margin-left: 0.5rem" class="seeMoreImg">
                      <img src="../assets/home/seemore-btn.png" alt="" />
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="product-bottom">
            <router-link
              class="product-bottom-img"
              v-for="item in productList"
              :key="item.id"
              :to="{
                name: 'productHard',
                params: { index: item.index },
              }"
            >
              <div class="product-bottom-img-hover">
                <h4>{{ item.title }}</h4>
              </div>
              <img v-bind:src="item.src" alt="" />
              <div class="product-bottom-img-text">{{ item.title }}</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- 课程资源 -->
    <div class="bj">
      <h1>课程资源</h1>
      <div class="border-bottom"></div>
      <div class="center-content">
        <el-carousel indicator-position="outside" arrow="never" color="plum">
          <el-carousel-item class="classZiYuan" v-for="item in classBanners" :key="item.url">
            <img v-bind:src="item.url" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="class-list" @click="routerPush('/contentClass')">查看课程详情</div>
    </div>
    <!-- 动态 -->
    <div class="center-box">
      <div>
        <h1>动态</h1>
        <div class="border-bottom"></div>
        <div class="move">
          <div style="max-height: 38rem; overflow: hidden">
            <div class="moveText-box" v-for="newsListItem in newsList" :key="newsListItem.id" @click="toNoticeList(newsListItem.id)">
              <div style="display: flex; align-items: center">
                <el-tag>{{ newsListItem.cateName }}</el-tag>
                <div class="move-title">
                  {{ newsListItem.title }}
                </div>
              </div>
              <p class="move-time">
                发布时间：<span>{{ newsListItem.createdAt }}</span>
              </p>
              <p class="move-text">
                {{ newsListItem.summary }}
              </p>
            </div>
          </div>
          <router-link to="/move">
            <a class="move-seemore"> 了解更多<img src="../assets/home/seemore-btn2.png" alt="" style="padding-left: 1.2rem" /> </a>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post, get } from '../utils/xajax'
export default {
  data() {
    return {
      arrList: [
        {
          title: '心理测评系统',
          index: '0',
          topImg: require('../assets/home/product-one-icon.png'),
          info: '心理测评系统支持定向测试和集体测试两种模式，经过定制量表、发布测评、开展测评、过程监控和结果管理几个步骤完成测评，最终自动生成测评报告。系统根据学校需求不同，提供了标准化量表库和个性化量表管理，学校可以选择标准量表，也可以导入个性化量表或者自定义量表，大大提高了…',
        },
        {
          title: '心理咨询系统',
          index: '1',
          topImg: require('../assets/home/product-seven-icon.png'),
          info: '系统根据不同的咨询场景提供多种咨询服务形式，保障同学们的咨询体验，具体形式包括：针对简单的咨询问题，通过智能咨询和机器人的方式解答；针对稍微复杂的常见问题，可以通过在线专家咨询的方式进行，支持实时和非实时两种模式；针对比较复杂的问题采用线下咨询的模式，支持咨询…',
        },
        {
          title: '课程教学系统',
          index: '2',
          topImg: require('../assets/home/product-five-icon.png'),
          info: '课程教学系统主要解决学校的心理课程教学问题，涵盖多种教学模式，一种是线上教学模式，通过提供精品在线课程或者个性化定制校本课程，实现学生自助线上学习，支持在线视频、作业、讨论和考试；一种是混合式教学模式，通过平台有效支撑教师进行教学改革，完成课前自主学习、课中讨…',
        },
        {
          title: '干预管理系统',
          index: '6',
          topImg: require('../assets/home/product-two-icon.png'),
          info: '干预管理系统是对需要干预的学生进行全程管理的信息化支撑工具，系统提供了全流程管理功能，方便地实现干预清单的及时分发、任务管理和结果管理。',
        },
        {
          title: '重点关注系统',
          index: '5',
          topImg: require('../assets/home/product-three-icon.png'),
          info: '测评产生的名单将进入访谈环节，由各院系通过访谈进一步确认学生的情况。访谈管理系统提供了任务分配、访谈管理、过程留痕等功能，有效地将院系负责人、辅导员组织到访谈工作中来，做到分工明确、过程可查、结果可控。访谈结果通过标签方式进一步分组，产生待咨询清单，进入咨询…',
        },
        {
          title: '访谈管理系统',
          index: '4',
          topImg: require('../assets/home/product-four-icon.png'),
          info: '心理测评系统支持定向测试和集体测试两种模式，经过定制量表、发布测评、开展测评、过程监控和结果管理几个步骤完成测评，最终自动生成测评报告。系统根据学校需求不同，提供了标准化量表库和个性化量表管理，学校可以选择标准量表，也可以导入个性化量表或者自定义量表，大大提高了…',
        },
        {
          title: '档案管理系统',
          index: '8',
          topImg: require('../assets/home/product-six-icon.png'),
          info: '通过全流程数据留痕，平台完整记录了学生在评测、课程、活动、咨询中的表现，完整记录了教师在平台进行教学、咨询和服务的完整表现，这样就可以生成学生和教师画像，以及学生和教师成长档案，实现“一生一档案，一师一档案”。还可以基于运行数据，生成《大学生心理健康咨询与教育…',
        },
        {
          title: '门户管理系统',
          index: '7',
          topImg: require('../assets/home/product-eight-icon.png'),
          info: '门户管理系统定位于展现学校心理健康咨询与教育工作的相关服务和成果，具体包括宣传心理健康教育相关政策，展示中心具体工作，及时发布组织活动，普及心理健康教育知识。满足学校建设融思想性、知识性、趣味性、服务性于一体的心理健康教育网站的需求，促进学生关注心理健康、助人…',
        },
        {
          title: '活动管理系统',
          index: '3',
          topImg: require('../assets/home/product-nine-icon.png'),
          info: '通过全流程数据留痕，平台完整记录了学生在评测、课程、活动、咨询中的表现，完整记录了教师在平台进行教学、咨询和服务的完整表现，这样就可以生成学生和教师画像，以及学生和教师成长档案，实现“一生一档案，一师一档案”。还可以基于运行数据，生成《大学生心理健康咨询与教育…',
        },
      ],
      productList: [
        {
          index: '1',
          title: '团体咨询室',
          src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/product-one.png',
          text: '可配置设备：团体活动包、游戏、素质拓展包、心理挂图及制度',
          info: '该室可用于提升人际交往能力、合作意识和个人优势开发。也可用于开展团体辅导活动，或者用于教师开展教学研讨、教师培训等活动。',
        },
        {
          index: '2',
          title: '情绪宣泄室',
          src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/product-two.png',
          text: '可配置设备：不倒翁人形沙袋、呐喊宣泄系统、打击宣泄系统等',
          info: '该室可用于通过各种道具和宣泄系统的应用，舒缓个人紧张情绪，让人回归平静。',
        },
        {
          index: '3',
          title: '音乐放松室',
          src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/product-three.png',
          text: '可配置设备：音乐治疗自助减压系统软件、心理挂图及制度',
          info: '该室可用于音乐放松训练，消除悲观、焦虑紧张等负面情绪，改善睡眠质量。该室亦可用于辅助催眠。',
        },
        {
          index: '4',
          title: '心理咨询室',
          src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/product-four.png',
          text: '可配置设备：沙发、茶几、绿植、心理图书、心理挂图及制度',
          info: '该室可用于倾吐心声、认识自我、增强来访者的心理适应能力、心理承受能力。',
        },
        {
          index: '5',
          title: '心理训练室',
          src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/product-five.png',
          text: '可配置设备：桌椅、电脑、沙发、系统软件、心理挂图及制度',
          info: '该室可用于情绪控制训练、抗挫折培训、学习压力管理。可提升幸福感、改善考试状态、缓解考试焦虑、改善人际关系。',
        },
        {
          index: '6',
          title: '沙盘游戏室',
          src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/product-six.png',
          text: '可配置设备：立柜、桌子、沙盘游戏、心理挂图及制度',
          info: '该室可通过沙盘游戏的非言语支持，激发自我力量，化解内心冲突与压力。',
        },
      ],
      classBanners: [{ url: require('../assets/home/banner1.png') }, { url: require('../assets/home/banner2.png') }, { url: require('../assets/home/banner3.png') }],
      newsList: [],
      banners: [],
    }
  },
  methods: {
    getList() {
      get('/index/home').then((res) => {
        this.newsList = res.data.newsList
        this.banners = res.data.banners
      })
    },
    toNoticeList(id) {
      this.$router.push(`/newsDetial/${id}`)
    },
    routerPush(path) {
      this.$router.push({ path })
    },
  },
  mounted() {
    this.getList()
  },
}
</script>

<style lang="less" scoped>
.box {
  width: 100%;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
// 内容图
.topImg > img {
  // height: 12.5rem;
  width: 100%;
  box-sizing: border-box;
}
.topImg {
  display: flex;
  position: relative;
  margin-top: 2rem;
}
.topImg-headline {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 1.2rem;
}
.topImg-title {
  font-size: 3.4rem;
  font-family: BradleyHandITCTT-Bold, BradleyHandITCTT;
  font-weight: 600;
  font-family: AlibabaPuHuiTiB;
  color: #ffffff;
  line-height: 4.1rem;
}
.topImg-text {
  font-size: 1.2rem;
  font-family: AlBayan;
  color: #ffffff;
  letter-spacing: 0.2rem;
}
// 中心内容
.center-box {
  padding: 0rem 1.2rem;
}

h1 {
  font-size: 2rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  text-align: center;
  height: 2.8rem;
  line-height: 2.8rem;
  padding: 2.8rem 0rem 0.3rem;
}
.border-bottom {
  width: 2.4rem;
  height: 0.2rem;
  background: #4676f8;
  border-radius: 0.1rem;
  margin: auto;
  margin-bottom: 2rem;
}
// 应用方案
.plan-content {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  width: 100%;
}
.plan-content-img {
  display: flex;
  position: relative;
  margin-top: 2.25rem;
}
.plan-content-img:first-child {
  margin-top: 0rem;
}
.plan-content-img:nth-child(2) {
  margin-top: 0rem;
}
.plan-content-img > img {
  width: 16.5rem;
  height: 7.7rem;
}
.plan-content-mark {
  position: absolute;
  // transform: translateX(-50%);
  transform: translate(-50%, -10%);
  left: 50%;
  top: 10%;
  text-align: center;
  white-space: nowrap;
}
.plan-content-mark > img {
  width: 3.5rem;
}
.plan-content-img-text {
  font-size: 1.3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 2rem;
}
.product-box {
  margin-bottom: 0.9rem;
  display: flex;
  justify-content: space-between;
  // 溢出部分剪切
  overflow: hidden;
  // 设置横向滚动
  overflow-x: scroll;
  // 禁止纵向滚动
  overflow-y: hidden;
  // 设置文本平铺
  text-align: justify;
  box-sizing: border-box;
}
// 	/*隐藏滚动条*/
.product-box::-webkit-scrollbar {
  display: none;
}

.product-content-list-content {
  display: flex;
  overflow-x: scroll;
  text-align: justify;
}

.chanPin-left-icon,
.chanPin-right-icon {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.chanPin-left-icon {
  margin-right: 2.25rem;
  left: -5rem;
}
.chanPin-right-icon {
  margin-left: 2.25rem;
  right: -5rem;
}
.product-content {
  width: 16rem;
  height: 19.7rem;
  border: 0.1rem solid #e1e1e1;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  margin-right: 1.2rem;
  margin-bottom: 0.5rem;
}
.product-top {
  display: flex;
  justify-content: space-between;
  height: 4.65rem;
  line-height: 4.65rem;
  background: linear-gradient(to right, #0156ff, #347afd);
}
.product-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: #ffffff;
  padding-left: 1.2rem;
}
.productImg > img {
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  width: 3.6rem;
  height: 3.6rem;
}
.product-text {
  // height: 9rem;
  margin: 1.2rem;
  line-height: 1.8rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: #3b3d41;
  // 多行省略号
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}
.seeMore {
  float: right;
  height: 1.1rem;
  line-height: 1.1rem;
  margin: 0 1.2rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: #0156ff;
  position: absolute;
  bottom: 1.6rem;
  right: 0rem;
  display: flex;
}
.seeMoreImg > img {
  width: 0.5rem;
}
.product-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
}
.product-bottom-img {
  margin-top: 1.6rem;
  height: 13.7rem;
  display: flex;
  position: relative;
}
.product-bottom-img > img {
  width: 16.5rem;
}
h4 {
  height: 1.4rem;
  font-size: 1rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 1.4rem;
  padding: 2rem 1.6rem 0.8rem;
}
.product-bottom-img-hover-text {
  height: 2rem;
  font-size: 0.7rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 1rem;
  padding: 0rem 1.6rem 1rem;
}
.hoverSeeMore {
  font-size: 0.8rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: white;
  line-height: 1.1rem;
}
.product-bottom-img-hover {
  width: 19.2rem;
  height: 13.7rem;
  background: #4676f8;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.product-bottom-img-text {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  color: #ffffff;
  height: 2.25rem;
  font-size: 1.6rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 2.25rem;
  white-space: nowrap;
}
// 课程资源
.bj {
  background-image: url(../assets/home/bjClass.png);
  margin-top: 3.7rem;
  height: 38.2rem;
}
.center-content {
  // width: 24.5rem;
  width: 29.3rem;
  margin: 0 auto;
}
.class-list {
  border-radius: 1.55rem;
  border: 0.05rem solid #0156ff;
  margin: 0 auto;
  width: 13.9rem;
  height: 3.1rem;
  line-height: 3.1rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: #0156ff;
  letter-spacing: 0.05rem;
  text-align: center;
  margin-top: 2.8rem;
  margin-bottom: 3rem;
}
/deep/.el-carousel__container {
  // height: 14rem;
  height: 19.2rem;
}
.classZiYuan > img {
  // width: 24.5rem;
  width: 29.3rem;
}
// 动态
.move {
  width: 100%;
  margin-top: 2rem;
}
.moveText-box:first-child {
  margin-top: 0rem;
}
.moveText-box {
  margin-top: 2.1rem;
}

.move-title {
  font-size: 1.6rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  margin-left: 0.7rem;
  overflow: hidden;
  text-overflow: ellipsis; //文本溢出显示省略号
  white-space: nowrap;
}
/deep/.el-tag {
  //   font-size: 1rem;
  margin-right: 1rem;
  border: 1px solid #0156ff;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.3rem;
  border-radius: 0.125rem /* 2/16 */;
}
.move-time {
  font-size: 1.1rem;
  font-family: MicrosoftYaHei;
  color: #6f7174;
  letter-spacing: 0.05rem;
  //   margin-top: 1rem;
  margin-top: 0.875rem /* 14/16 */;
}
.move-text {
  font-size: 20px;
  font-family: 'Courier New', Courier, monospace;
  color: #6f7174;
}

.move-text {
  font-size: 1.2rem;
  font-family: MicrosoftYaHei;
  color: #3b3d41;
  margin-top: 1rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.move-seemore {
  display: block;
  height: 2.25rem;
  font-size: 1.6rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0156ff;
  line-height: 2.25rem;
  margin-top: 1.6rem;
  margin-bottom: 2.8rem;
  text-align: center;
}
.move-seemore > img {
  width: 0.8rem;
}
</style>