<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/product/top-img-ying.png" alt="" />
      <!-- <div class="topImg-headline">
        <div class="topImg-title">产品-硬件</div>
      </div> -->
    </div>
    <!-- 中心内容 -->
    <div class="content-centre">
      <el-row class="tac">
        <el-col :span="12">
          <el-menu default-active="1" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" background-color ref="menu">
            <!-- 团体咨询室 -->
            <el-submenu index="1">
              <template slot="title">
                <img src="../assets/product/one.png" alt="" />
                <h3 style="padding-left: 1.6rem">团体咨询室</h3>
              </template>
              <div class="yingJian-img">
                <img src="../assets/product/yingjian-one.png" alt="" />
              </div>
              <el-menu-item-group>
                <el-menu-item index="1-1">
                  <div class="yingjian-content">
                    <div>
                      <!-- <h4>团体咨询室</h4> -->
                      <div class="h4-title">团体咨询室</div>
                      <p class="yingjian-text">可配置设备：团体活动包、游戏、素质拓展包、心理挂图及制度</p>
                      <p class="yingjian-text">该室可用于提升人际交往能力、合作意识和个人优势开发。也可用于开展团体辅导活动，或者用于教师开展教学研讨、教师培训等活动。</p>
                    </div>
                  </div>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- 情绪宣泄室 -->
            <el-submenu index="2">
              <template slot="title">
                <img src="../assets/product/one.png" alt="" />
                <h3 style="padding-left: 1.6rem">情绪宣泄室</h3>
              </template>
              <div class="yingJian-img">
                <img src="../assets/product/yingjian-two.png" alt="" />
              </div>
              <el-menu-item-group>
                <el-menu-item index="1-1">
                  <div class="yingjian-content">
                    <div>
                      <!-- <h4>情绪宣泄室</h4> -->
                      <div class="h4-title">情绪宣泄室</div>
                      <p class="yingjian-text">可配置设备：不倒翁人形沙袋、呐喊宣泄系统、打击宣泄系统等。</p>
                      <p class="yingjian-text">该室可用于通过各种道具和宣泄系统的应用，舒缓个人紧张情绪，让人回归平静。</p>
                    </div>
                  </div>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- 音乐放松室 -->
            <el-submenu index="3">
              <template slot="title">
                <img src="../assets/product/one.png" alt="" />
                <h3 style="padding-left: 1.6rem">音乐放松室</h3>
              </template>
              <div class="yingJian-img">
                <img src="../assets/product/yingjian-three.png" alt="" />
              </div>
              <el-menu-item-group>
                <el-menu-item index="1-1">
                  <div class="yingjian-content">
                    <div>
                      <!-- <h4>音乐放松室</h4> -->
                      <div class="h4-title">音乐放松室</div>
                      <p class="yingjian-text">可配置设备：音乐治疗自助减压系统软件、心理挂图及制度。</p>
                      <p class="yingjian-text">该室可用于音乐放松训练，消除悲观、焦虑紧张等负面情绪，改善睡眠质量。该室亦可用于辅助催眠。</p>
                    </div>
                  </div>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">
                <img src="../assets/product/one.png" alt="" />
                <h3 style="padding-left: 1.6rem">心理咨询室</h3>
              </template>
              <div class="yingJian-img">
                <img src="../assets/product/yingjian-four.png" alt="" />
              </div>
              <el-menu-item-group>
                <el-menu-item index="1-1">
                  <div class="yingjian-content">
                    <div>
                      <!-- <h4>心理咨询室</h4> -->
                      <div class="h4-title">心理咨询室</div>
                      <p class="yingjian-text">可配置设备：沙发、茶几、绿植、心理图书、心理挂图及制度。</p>
                      <p class="yingjian-text">该室可用于倾吐心声、认识自我、增强来访者的心理适应能力、心理承受能力。</p>
                    </div>
                  </div>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- 心理训练室 -->
            <el-submenu index="5">
              <template slot="title">
                <img src="../assets/product/one.png" alt="" />
                <h3 style="padding-left: 1.6rem">心理训练室</h3>
              </template>
              <div class="yingJian-img">
                <img src="../assets/product/yingjian-five.png" alt="" />
              </div>
              <el-menu-item-group>
                <el-menu-item index="1-1">
                  <div class="yingjian-content">
                    <div>
                      <!-- <h4>心理训练室</h4> -->
                      <div class="h4-title">心理训练室</div>
                      <p class="yingjian-text">可配置设备：桌椅、电脑、沙发、系统软件、心理挂图及制度。</p>
                      <p class="yingjian-text">该室可用于情绪控制训练、抗挫折培训、学习压力管理。可提升幸福感、改善考试状态、缓解考试焦虑、改善人际关系。</p>
                    </div>
                  </div>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- 沙盘游戏室 -->
            <el-submenu index="6">
              <template slot="title" class="titleImg">
                <img src="../assets/product/one.png" alt="" />
                <h3 style="padding-left: 1.6rem">沙盘游戏室</h3>
              </template>
              <div class="yingJian-img">
                <img src="../assets/product/yingjian-six.png" alt="" />
              </div>
              <el-menu-item-group>
                <el-menu-item index="1-1">
                  <div class="yingjian-content">
                    <div>
                      <!-- <h4>沙盘游戏室</h4> -->
                      <div class="h4-title">沙盘游戏室</div>
                      <p class="yingjian-text">可配置设备：立柜、桌子、沙盘游戏、心理挂图及制度。</p>
                      <p class="yingjian-text">该室可通过沙盘游戏的非言语支持，激发自我力量，化解内心冲突与压力。</p>
                    </div>
                  </div>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      c1: ['c1'],
      isCollapse: true,
      index: '1',
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
  },
  mounted() {
    let menuIndex = this.$route.params.index || '1'
    this.$refs.menu.open(menuIndex)
  },
}
</script>

<style lang="less" scoped>
.box {
  background: #f4f8ff !important;
}
* {
  text-decoration: none;
  margin: 0rem;
  padding: 0rem;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
.topImg {
  display: flex;
  position: relative;
  margin-top: 2rem;
}
.topImg > img {
  width: 100%;
  height: 7.1rem;
}
.topImg-headline {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 24px;
}
.topImg-title {
  font-size: 1.8rem;
  font-family: AlibabaPuHuiTiB;
  color: #ffffff;
}
.content-centre {
  padding: 0rem 1.2rem;
  margin-top: 2rem;
}
.yingjian-content {
  background: #f8faff;
}
.yingJian-img > img {
  width: 35.1rem;
  display: block;
}
.yingjian-text {
  width: 31.9rem;
  font-size: 1.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  // height: 5.8rem;
  line-height: 2.4rem;
}
h3 {
  display: inline-block;
  width: 8rem;
  font-size: 1.6rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 2.25rem;
}
.h4-title {
  height: 2.8125rem;
  font-size: 2rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  font-weight: 600;
  color: #3b3d41;
  line-height: 2.8125rem;
  padding-top: 1.5rem;
  margin-bottom: 1.25rem;
}
/deep/.el-submenu__title {
  width: 35.1rem;
  height: 4.85rem;
  line-height: 4.85rem;
  border: 0.1rem solid #e1e1e1;
}
/deep/.el-submenu__title:hover {
  background: white;
}
/deep/.el-submenu__title > img {
  padding-left: 1.2rem;
  width: 2.7rem;
}
/deep/.el-menu-item {
  white-space: normal;
}
/deep/ .el-menu-item {
  width: 35.1rem;
  // height: 18rem !important;
  height: 20rem !important;
}
/deep/.el-col-12 {
  width: 100%;
  padding-bottom: 2.8rem;
}
/deep/.el-menu-item {
  vertical-align: baseline;
}
/deep/.el-menu-item-group > ul {
  background: #f8faff;
  padding: 0rem 1rem;
}
/deep/.el-menu {
  width: 100%;
  border: 1px solid #e1e1e1;
}
/deep/.el-submenu__icon-arrow {
  top: 50%;
  transform: translateY(-30%);
}


</style>