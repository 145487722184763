import Vue from 'vue';
import VueRouter from 'vue-router';
import home from '../views/home';
import homeLayout from '../layout/homeLayout.vue';
import move from '../views/move';
import schemeHeight from '../views/schemeHeight';
import schemeBasics from '../views/schemeBasics';
import schemeTrade from '../views/schemeTrade';
import aboutUs from '../views/aboutUs';
import contentClass from '../views/contentClass';
import contentSelf from '../views/contentSelf';
import schemeTrain from '../views/schemeTrain';
import productHard from '../views/productHard';
import productSoft from '../views/productSoft';
import newsDetial from '../views/newsDetial';
import videoDetail from '../views/videoDetail';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: homeLayout,
    redirect: 'home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: home,
        meta: {
          title: '学银通融(北京)教育科技有限公司',
        },
      },
      {
        path: 'move',
        name: 'news',
        component: move,
        meta: {
          title: '动态-学银通融(北京)教育科技有限公司',
        },
      },
      {
        path: 'schemeHeight',
        name: 'schemeHeight',
        component: schemeHeight,
        meta: {
          title: '高等教育-学银通融(北京)教育科技有限公司',
        },
      },
      {
        path: 'schemeBasics',
        name: 'schemeBasics',
        component: schemeBasics,
        meta: {
          title: '基础教育-学银通融(北京)教育科技有限公司',
        },
      },
      {
        path: 'schemeTrain',
        name: 'schemeTrain',
        component: schemeTrain,
        meta: {
          title: '教师培训-学银通融(北京)教育科技有限公司',
        },
      },
      {
        path: 'schemeTrade',
        name: 'schemeTrade',
        component: schemeTrade,
        meta: {
          title: '中等职业教育-学银通融(北京)教育科技有限公司',
        },
      },
      {
        path: 'aboutUs',
        name: 'aboutUs',
        component: aboutUs,
        meta: {
          title: '关于我们-学银通融(北京)教育科技有限公司',
        },
      },
      {
        path: 'contentClass',
        name: 'contentClass',
        component: contentClass,
        meta: {
          title: '课程资源-学银通融(北京)教育科技有限公司',
        },
      },
      {
        path: 'contentSelf',
        name: 'contentSelf',
        component: contentSelf,
        meta: {
          title: '自助资源-学银通融(北京)教育科技有限公司',
        },
      },
      {
        path: 'productHard',
        name: 'productHard',
        component: productHard,
        meta: {
          title: '硬件-学银通融(北京)教育科技有限公司',
        },
      },
      {
        path: 'productSoft',
        name: 'productSoft',
        component: productSoft,
        meta: {
          title: '软件-学银通融(北京)教育科技有限公司',
        },
      },
      {
        path: 'videoDetail/:id',
        name: 'videoDetail',
        component: videoDetail,
        meta: {
          title: '视频播放-学银通融(北京)教育科技有限公司',
        },
      },
      {
        path: 'newsDetial/:artId',
        name: 'newsDetial',
        component: newsDetial,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
