<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/course/top-img-3.png" alt="" />
    </div>
    <div class="course-box">
      <div class="course-nav">
        <div :class="{ 'course-nav-list': true, tabActive: tabActive == 0 }" @click="tabChange(0)"><span>全部 </span>
        </div>
        <div :class="{ 'course-nav-list': true, tabActive: tabActive == cateDataItem.cateId }"
          v-for="cateDataItem in cateData" :key="cateDataItem.cateid" @click="tabChange(cateDataItem.cateId)">{{
              cateDataItem.cateName
          }}</div>
      </div>
      <!-- 课程主体部分 -->
      <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh">  v-infinite-scroll="load"-->
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="learnCourse" style="overflow: auto; min-height: 30rem; width: 100%">
          <div class="learnCourse-list" v-for="cataDataListItem in cataDataList" :key="cataDataListItem.courseId"
            @click="toClass(cataDataListItem.id)">
            <div class="learnCourse-list-img">
              <el-image v-bind:src="cataDataListItem.coverImg" scroll-container=".learnCourse" lazy />
            </div>
            <div class="learnCourse-list-title">{{ cataDataListItem.title }}</div>
            <div class="learnCourse-list-text">
              <span>讲师：{{ cataDataListItem.lecturer }}</span> <span class="videoCount">{{ cataDataListItem.viewCount
              }}次观看</span>
            </div>
          </div>
          <div class="no-content" v-if="!cataDataList.length">
            <img src="../assets/home/no-content.png" alt="">
          </div>
        </div>
      </van-list>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>

<script>
import { post, get } from '../utils/xajax'


export default {
  data() {
    return {
      tabActive: 0,
      cataDataList: [],
      cateData: [],
      cateId: 0,
      page: 1,
      total: 0,
      loading: false,
      finished: true,
      refreshing: false,
    }
  },
  methods: {
    onLoad() {
      this.page++
      this.getCourseList();
    },
    // onRefresh() {
    //   // 清空列表数据
    //   this.finished = false;
    //   // 重新加载数据
    //   // 将 loading 设置为 true，表示处于加载状态
    //   this.loading = true;
    //   this.onLoad();
    // },
    //导航列表
    getCourseNav() {
      get('/smallvideo/category')
        .then((res) => {
          this.cateData = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //课程列表
    getCourseList(cateId) {
      get('/smallvideo/videos', { cateId: this.cateId, page: this.page })
        .then((res) => {
          // this.cataDataList.push(...res.data.lists)
          // this.total = res.data.count
          if (res.data.lists.length == 0) {
            this.finished = true;
          } else {
            this.finished = false
          }
          if (this.page == 1) {
            this.cataDataList = res.data.lists;
          } else {
            this.cataDataList = this.cataDataList.concat(res.data.lists);
          }
          this.total = res.data.total;
        })
        .catch((err) => {
          console.log(err)
        }).finally(() => {
          this.loading = false;
        })
    },
    tabChange(cateId) {
      this.cateId = cateId
      this.page = 1
      this.cataDataList = [];
      this.tabActive = cateId
      this.getCourseList(cateId)
    },
    toClass(id) {
      this.$router.push(`/videoDetail/${id}`)
    },
  },
  created() { },
  computed: {},
  mounted() {
    this.getCourseNav()
    this.getCourseList()
  },
}
</script>

<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0rem;
  padding: 0rem;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

.topImg {
  display: flex;
  position: relative;
  margin-top: 2rem;
}

.topImg>img {
  width: 100%;
  height: 7.1rem;
}

.topImg-headline {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 1.2rem;
}

.topImg-title {
  font-size: 1.8rem;
  font-family: AlibabaPuHuiTiB;
  color: #ffffff;
}

.course-box {
  padding: 0 1rem;
}

.banner {
  margin-top: 0.2rem;
}

.banner>img {
  width: 100%;
}

.course-nav {
  display: flex;
  margin-top: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.1rem;
  margin-bottom: 1rem;
  padding-left: 0.1rem;
  // 溢出部分剪切
  overflow: hidden;
  // 设置横向滚动
  overflow-x: scroll;
  // 禁止纵向滚动
  overflow-y: hidden;
  // 文本平铺
  text-align: justify;
  box-sizing: border-box;
  // 文本不允许换行
  white-space: nowrap;
}

.course-nav::-webkit-scrollbar {
  display: none;
}

.course-nav-list {
  margin-right: 2.8rem;
  padding: 0 0.4rem;
  font-weight: 400;
  color: #3b3d41;
  font-size: 1.6rem;
  border-radius: 0.7rem;
  cursor: pointer;
  font-family: PingFangSC-Regular, PingFang SC;
}

.tabActive {
  color: #4676f8;
  border-radius: 1.1rem;
  box-shadow: 0 0 0 0.05rem #4676f8;
  background: rgba(70, 118, 248, 0.05);
  padding: 0 0.5rem;
}

.learnCourse {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 0.3rem;
  // height: 500px;
  // overflow-y: scroll;
}

.learnCourse::-webkit-scrollbar {
  display: none;
}

.learnCourse-list {
  width: 16.5rem;
  margin-bottom: 1.6rem;
}

.learnCourse-list:last-child {
  padding-bottom: 2.9rem;
}

.learnCourse-list-img>img {
  width: 7.5rem;
  height: 5rem;
  border-radius: 0.3rem;
}

.learnCourse-list-img>.el-image {
  width: 16.5rem;
  height: 10rem;
}

.learnCourse-list-title {
  padding-top: 1.2rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: #3b3d41;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

.learnCourse-list-text {
  padding-top: 0.75rem;
  font-weight: 400;
  color: #9b9da2;
  font-size: 1.1rem;
}

.hot-class-icon {
  display: flex;
}

.hot-class-num {
  width: 8.85rem;
  height: 0.95rem;
  font-size: 1.1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9b9da2;
  line-height: 0.9rem;
}

.hot-class-icon>img {
  width: 1.2rem;
  height: 1.2rem;
}

.no-content {
  text-align: center;
  margin: 2.5rem auto 3rem;
}

.no-content>img {
  // width: 50%;
  // height: 50%;
  height: 26.25rem;
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.el-image {
  display: block;
  min-height: 0.05rem;
}

.videoCount {
  margin-left: 0.75rem
    /* 12/16 */
  ;
}
</style>