<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/aboutUs/top-img.png" alt="" />
      <!-- <div class="topImg-headline">
        <div class="topImg-title">关于我们</div>
        <div class="topImg-text">从心出发 让生命充满力量</div>
      </div> -->

    </div>
    <!-- 中心内容 -->
    <div class="center-box">
      <h1>公司简介</h1>
      <div class="border-bottom"></div>
      <div>
        <div class="content-one">
          <img src="../assets/aboutUs/firm-img.png" alt="" />
        </div>

        <p class="firm-name">学银通融(北京)教育科技有限公司</p>
        <p class="content-one-text">
          “心灵通”聚焦AI+心理健康，为大中小幼学校提供心理“教育+咨询”数字化、全流程、一站式整体解决方案，是国内专业的心理健康服务平台，承担教育部“高校学生心理健康管理动态分析与会商指导信息化平台”的技术研发与运营服务。
        </p>
        <div class="flexBox">
          <p class="listIcon"><img src="../assets/aboutUs/list-icon.png" alt=""></p>
          <p class="list-text"> 学习强国教育频道合作伙伴</p>
        </div>
        <div class="flexBox">
          <p class="listIcon"><img src="../assets/aboutUs/list-icon.png" alt=""></p>
          <p class="list-text"> 中国高等教育学会理事单位</p>
        </div>
        <div class="flexBox">
          <p class="listIcon"><img src="../assets/aboutUs/list-icon.png" alt=""></p>
          <p class="list-text"> 中国教育技术协会副秘书长单位</p>
        </div>
        <div class="flexBox">
          <p class="listIcon"><img src="../assets/aboutUs/list-icon.png" alt=""></p>
          <p class="list-text"> 职业教育国家学分银行联盟单位</p>
        </div>
        <div class="flexBox">
          <p class="listIcon"><img src="../assets/aboutUs/list-icon.png" alt=""></p>
          <p class="list-text"> 国家智慧职教学习平台战略合作单位</p>
        </div>
        <div class="flexBox">
          <p class="listIcon"><img src="../assets/aboutUs/list-icon.png" alt=""></p>
          <p class="list-text"> 中国社会工作联合会青少年与学校工作委员会会员单位</p>
        </div>
        <div class="flexBox">
          <p class="listIcon"><img src="../assets/aboutUs/list-icon.png" alt=""></p>
          <p class="list-text"> 中国教育技术协会智慧心理专业委员会（筹）秘书长单位</p>
        </div>
        <h1>产品及服务</h1>
        <div class="border-bottom"></div>
        <div class="banner-box">
          <!-- <div class="item">
            <div class="top">
              <img src="../assets/aboutUs/product1.png" alt="">
            </div>
            <div class="bom">
              <p class="title">测评及数据服务</p>
              <p>已经覆盖全国2779所高校，成为全国大学生心理健康最权威的数据库，基本掌握了全国范围内大学新生的心理健康总体状况。</p>
            </div>
          </div> -->
          <div class="item">
            <div class="top">
              <img src="../assets/aboutUs/product2.png" alt="">
            </div>
            <div class="bom">
              <p class="title">数字化工作台</p>
              <p class="size12">包括心理测评、访谈、评估、预约、咨询、转介、危机干预、课程学习、活动管理等不同功能模块，已经服务300多家院校。</p>
            </div>
          </div>
          <div class="item">
            <div class="top">
              <img src="../assets/aboutUs/product3.png" alt="">
            </div>
            <div class="bom">
              <p class="title">教学资源库系统</p>
              <p class="size12">与阅文集团、中国高等教育出版社、天津大学出版社合作，涵盖2000多册心理健康图书、400多门心理微课、27门国家级、省级金课</p>
            </div>
          </div>
          <div class="item">
            <div class="top">
              <img src="../assets/aboutUs/product4.png" alt="">
            </div>
            <div class="bom">
              <p class="title">智能硬件</p>
              <p class="size12">心理健康视频咨询师、沉浸式AR放松仪、陪伴式心理聊天虚拟人、心理健康教育一体机等智能硬件产品，逐步形成体系、规模，落地应用于各级各类教育场景。</p>
            </div>
          </div>
          <div class="item">
            <div class="top">
              <img src="../assets/aboutUs/product5.png" alt="">
            </div>
            <div class="bom">
              <p class="title">师资培训服务</p>
              <p class="size12">整合行业专家资源，为学校提供涵盖从线上到线下、从理论到实践、从基础到专业的主题培训、系列培训、定制化研讨会及特色工作坊，为心理健康工作保驾护航。</p>
            </div>
          </div>
        </div>
        <div class="firm-introduce">
          <div class="center-one-content-bottom-item">
            <div class="flexBox-center">
              <p class="school-icon"><img src="../assets/aboutUs/school-icon.png" alt=""></p>
              <p class="num">1000+</p>
            </div>
            <p class="topic">服务高校</p>
          </div>
          <div class="center-one-content-bottom-item">
            <div class="flexBox-center">
              <p class="school-icon"><img src="../assets/aboutUs/student-icon.png" alt=""></p>
              <p class="num">400万+</p>
            </div>
            <p class="topic">学生</p>
          </div>

          <div class="center-one-content-bottom-item">
            <div class="flexBox-center">
              <p class="school-icon"><img src="../assets/aboutUs/psybook-icon.png" alt=""></p>
              <p class="num">2000+</p>
            </div>
            <p class="topic">心理图书</p>
          </div>

          <div class="center-one-content-bottom-item">
            <div class="flexBox-center">
              <p class="school-icon"><img src="../assets/aboutUs/psyvideo-icon.png" alt=""></p>
              <p class="num">1000+</p>
            </div>
            <p class="topic">心理视频</p>
          </div>
        </div>
      </div>
      <!-- 资质荣誉 -->
      <div>
        <h1>资质荣誉</h1>
        <div class="border-bottom"></div>
        <div class="certification">
          <img src="../assets/aboutUs/certification2.png" alt="">
        </div>
      </div>

      <!-- 招贤纳士 -->
      <div>
        <h1>招贤纳士</h1>
        <div class="border-bottom"></div>
        <div class="invite">
          <div class="inviteimg"><img src="../assets/aboutUs/two.png" alt="" /></div>
          <p class="invite-text">公司处于快速发展期，提供有竞争力的薪酬待遇，欢迎优秀人才加盟。</p>
        </div>
        <div class="wire"></div>
        <div class="invite-job">
          <li class="jobTitle">JAVA开发工程师：</li>
          <p class="jobText">负责公司软件产品的开发工作</p>
        </div>
        <div>
          <li class="jobTitle">内容策划：</li>
          <p class="jobText">负责心理学相关课程和内容产品的策划、组织和实施</p>
        </div>
        <div>
          <li class="jobTitle">市场营销</li>
          <p class="jobText">负责在各省进行心理健康相关产品的推广工作</p>
        </div>

        <div class="wireEnd"></div>
      </div>


      <!-- 关于我们 百度地图位置 -->
      <!-- <div style="margin-bottom: 3.5rem">
        <h1>关于我们</h1>
        <div class="border-bottom"></div>
        <div class="linkMan">
          <baidu-map class="map" :center="center" :zoom="zoom" :scroll-wheel-zoom="true" @ready="handler">
            <bm-marker :position="pixel" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
              <bm-label :content="content" :labelStyle="{ border: 'none', color: '#6F7174', fontSize: '.8rem' }"
                :offset="{ width: -35, height: 30 }" />
            </bm-marker>
          </baidu-map>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      center: { lng: 0, lat: 0 }, //经纬度
      zoom: 3, //地图展示级别
      pixel: { lng: 116.361317, lat: 39.928825 }, //标识
      content: '学银通融（北京）教育科技有限公司',
    }
  },
  methods: {
    handler ({ BMap, map }) {
      this.center = { lng: 116.361317, lat: 39.928825 }
      // this.pixel = {lng: 116.361317, lat: 39.928825}
      this.zoom = 18
    },
  },
}
</script>

<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0rem;
  padding: 0rem;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

.topImg {
  display: flex;
  position: relative;
  margin-top: 2rem;
}

.topImg>img {
  width: 100%;
  height: 7.1rem;
}

.topImg-headline {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 1.2rem;
}

.topImg-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #ffffff;
  // line-height: 4.1rem;
}

.topImg-text {
  font-size: 1.4rem;
  color: #ffffff;
  letter-spacing: 0.2rem;
}

// 中心内容
.center-box {
  padding: 0rem 1.2rem;
}

h1 {
  font-size: 2rem;
  font-weight: 600;
  color: #3b3d41;
  text-align: center;
  height: 2.8rem;
  line-height: 2.8rem;
  padding: 2.8rem 0rem 0.3rem;
}

.border-bottom {
  width: 2.4rem;
  height: 0.2rem;
  background: #4676f8;
  border-radius: 0.1rem;
  margin: auto;
  margin-bottom: 2rem;
}

.content-one>img {
  width: 100%;
  display: block;
}

.firm-name {
  font-size: 1.75rem;
  text-align: center;
  font-weight: 600;
  color: #3B3D41;
  margin: 2rem 0rem 0rem;
}

.content-one-text {
  font-size: 1.4rem;
  font-weight: 400;
  color: #3b3d41;
  line-height: 2.4rem;
  text-align: justify;
  margin-top: 1.25rem;
}

.flexBox {
  display: flex;
  // align-items: center;
  margin-top: 1rem;
}

.listIcon>img {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: .75rem;
}

.firm-introduce {
  margin-top: 1.25rem;
  height: 12.3125rem;
  background: #FFFFFF;
  box-shadow: 0rem .125rem .75rem 0rem rgba(1, 86, 255, 0.14);
  border-radius: .375rem;
  display: flex;
  flex-wrap: wrap;
  padding: 0 3.5rem 1.125rem;
  justify-content: space-evenly;
}

.center-one-content-bottom-item {
  margin-right: 5rem;
  margin-top: 1.25rem;
  width: 9.375rem;
}

.center-one-content-bottom-item:nth-child(2n) {
  margin-right: 0rem;
}

.flexBox-center {
  display: flex;
  align-items: center;
}

.school-icon>img {
  width: 1.6875rem;
  height: 1.5625rem;
  margin-right: .625rem;
}

.num {
  font-size: 2rem;
  font-weight: 600;
  color: #3b3d41;
  letter-spacing: 0.25rem;
}

.topic {
  font-size: 1.125rem;
  font-weight: 500;
  color: #3B3D41;
  text-align: center;
}

// 资质荣誉
.certification>img {
  width: 100%;
}

// 招贤纳士
.invite {
  position: relative;
}

.inviteimg>img {
  width: 100%;
}

.invite-text {
  height: 4.8rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 2.4rem;
  padding: 0 1.2rem;
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
}

.wire {
  width: 100%;
  border: 0.0625rem solid #e1e1e1;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.wireEnd {
  width: 100%;
  border: 0.0625rem solid #e1e1e1;
  margin-bottom: 3.5rem;
}


.map {
  width: 100%;
  height: 100%;
  // width: 400px;
  height: 150px;
}

.jobTitle,
.jobText {
  //   font-size: 2rem /* 32/16 */;
  font-size: 1.125rem;
  padding-left: 1.25rem
    /* 20/16 */
  ;
}

.jobText {
  margin-bottom: 1.5rem;
}

.jobTitle {
  font-size: 1.6rem;
  //   font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.5rem
    /* 8/16 */
  ;
}

.jobTitle::marker {
  color: #0156ff;
}

// .jobTitle::after {
//   content: '';
//   position: absolute;
//   left: -0.5rem;
//   top: 0.8rem;
//   display: block;
//   width: 0.4rem;
//   height: 0.4rem;
//   border-radius: 1rem;
//   background: #0156ff;
// }
.banner-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .item {
    width: 48%;
    margin-bottom: 1rem;
    .top {
      img {
        display: block;
        width: 100%;
        height: 10rem;
      }
    }
    .bom{
      .title {
        font-size: 18px;
        font-weight: bold;
        color: #3B3D41;
        padding: 0.7rem;
      }
    }
  }
}
.size12{
  font-size: 12px !important;
}
</style>