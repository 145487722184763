<template>
  <div id="app">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {},
  created() {},
  computed: {},
  mounted() {
    window.addEventListener(
      'orientationchange' in window ? 'orientationchange' : 'resize',
      (function () {
        //判断是屏幕旋转还是resize
        function c() {
          var d = document.documentElement //获取html元素
          var cw = d.clientWidth || 750
          // console.log(cw)
          d.style.fontSize = 10 * (cw / 375) > 40 ? 40 + 'px' : 10 * (cw / 375) + 'px'
          //   d.style.fontSize = 20 * (cw / 375) > 40 ? 40 + 'px' : 20 * (cw / 375) + 'px'
        }
        c()
        return c
      })(),
      false
    )
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) == false) {
      window.location.href = 'https://www.x-lingtong.com'
    }
    // console.log(this.$device)
    // if(!this.$device.mobile){
    //   window.location.href = "https://www.x-lingtong.com";
    // }
    // var version = this.version;
    // console.log(version)
    // console.log('当前版本号common.js',version)
    // var vvv = '1.1.5'
    // console.log('当前版本号app.vue',vvv)
    // if(vvv!=version){
    //   this.version=vvv;
    //   location.reload();
    // }
  },
}
</script>
<style lang="less">
// li{
//   list-style: none;
//   position: relative;
// }
// li::after{
//   content: '';
//   position: absolute;
//   left: -10px;
//   top: 5px;
//   display: block;
//   width: 4px;
//   height: 4px;
//   border-radius: 2px;
//   background: red;
// }
</style>
