<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/move/top-img.png" alt="" />
      <!-- <div class="topImg-headline">
              <div class="topImg-title">动态</div>
              <div class="topImg-text">从心出发 让生命充满力量</div>
          </div> -->
    </div>
    <div class="news-box">
      <div v-if="tabsActive == 'newsMessage'" class="learnCourse" style="overflow: auto; width: 100%"
        v-infinite-scroll="load">
        <div class="activityCenter-list" v-for="artListItem in artList" :key="artListItem.id"
          @click="toNewsDetial(artListItem.id)">
          <div class="activityCenter-list-img">
            <el-image v-bind:src="artListItem.imgUrl" scroll-container=".learnCourse" lazy />
          </div>
          <div class="activityCenter-list-text">
            <div>
              <div style="display: flex; align-items: center">
                <!-- <el-tag v-if="artListItem.cateName == '政策文件'">文件</el-tag> -->
                <el-tag>{{ artListItem.cateName }}</el-tag>
                <div class="activityCenter-list-text-title">{{ artListItem.title }}</div>
              </div>
              <p class="activityCenter-list-text-info">{{ artListItem.summary }}</p>
              <div style="display: flex; align-items: baseline; margin-top: 0.625rem">
                <div class="time">{{ artListItem.createdAt }}</div>
                <div class="liulan">
                  <img src="../assets/move/liulan.png" alt="" />
                  <p>{{ artListItem.testNum }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="no-content" v-if="!artList.length">
           <img src="../assets/home/no-content.png" alt="">
         </div> -->
      <div class="no-content" v-if="!artList.length">
        <img src="../assets/home/no-content.png" alt="">
      </div>
    </div>



  </div>
</template>

<script>
import { post, get } from '../utils/xajax'
export default {
  data() {
    return {
      tabsActive: 'newsMessage',
      artList: [],
      page: 1,
    }
  },
  methods: {
    load() {
      //   if (this.page == -1) return
      //   this.page++
      //   this.getNewsList()
    },

    // 请求文章列表
    getNewsList() {
      let cateName = this.$route.name
      get('/article/lists', { cateName: cateName, page: this.page })
        .then((res) => {
          //   this.artList = res.data.artList
          this.total = res.data.total
          //   this.artList = this.artList.concat(res.data.artList)
          this.artList.push(...res.data.artList)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    toNewsDetial(id) {
      this.$router.push({ path: `/newsDetial/${id}` })
    },

    //触底触发函数
    listenBottomOut() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop //滚动高度
      let clientHeight = document.documentElement.clientHeight //可视区域屏幕高度
      let scrollHeight = document.documentElement.scrollHeight //页面高度
      //   console.log(scrollTop)
      //   console.log(clientHeight)
      //   console.log(scrollHeight)
      //   if (scrollTop + clientHeight >= scrollHeight) {
      if (this.artList.length >= 10) {
        this.getNewsList(this.page++)
      } else {
        return
      }
      // 没有数据后，不触发请求
      if (!this.artList) {
        this.getNewsList((page = 1))
      }
      //   }
    },
  },
  created() { },
  computed: {},
  mounted() {
    this.getNewsList()
    //事件监听
    window.addEventListener('scroll', this.listenBottomOut)
  },
}
</script>

<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0rem;
  padding: 0rem;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

.topImg>img {
  width: 100%;
  height: 7.1rem;
}

.topImg {
  display: flex;
  position: relative;
  margin-top: 2rem;
}

.topImg-headline {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 1.2rem;
}

.topImg-title {
  font-size: 1.8rem;
  font-weight: 600;
  font-family: AlibabaPuHuiTiB;
  color: #ffffff;
}

.topImg-text {
  font-size: 1.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #ffffff;
  letter-spacing: 0.2rem;
}

.news-box {
  padding: 0 1rem;
}

.activityCenter-box {
  padding: 0 1rem;
}

.learnCourse::-webkit-scrollbar {
  display: none;
}

.activityCenter-list {
  margin-top: 2rem;
  display: flex;
}

.activityCenter-list:last-child {
  margin-bottom: 2.8rem;
}

.activityCenter-list:last-child {
  padding-bottom: 1.2rem;
}

.activityCenter-box:last-child {
  margin-bottom: 2.8rem;
}

.activityCenter-list-text {
  display: flex;
  align-items: center;
  width: 18.5rem;
  padding-left: 1.2rem;
}

.activityCenter-list-text>div {
  width: 100%;
}

.activityCenter-list-img>img {
  width: 7.1rem;
  height: 100%;
  border-radius: 0.3rem;
}

.activityCenter-list-text-title {
  font-size: 1.6rem;
  font-weight: 600;
  color: #3b3d41;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

.activityCenter-list-text-info {
  font-size: 1.2rem;
  font-weight: 400;
  color: #9b9da2;
  //   padding-top: .65rem;
  padding-top: 0.8rem;
  // 多行省略号
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.75rem;
}

.time {
  font-size: 1.1rem;
  font-weight: 400;
  color: #9b9da2;
  // padding-top: .3rem;
  display: inline-block;
  margin-right: 1.6rem;
  white-space: nowrap;
}

.no-content {
  text-align: center;
}

.no-content>img {
  width: 50%;
  height: 50%;
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.el-image {
  width: 15.5rem;
  display: block;
}

.liulan {
  display: flex;
  align-items: center;
}

.liulan>img {
  width: 1.1rem;
  height: 0.95rem;
  margin-right: 0.3rem;
}

.liulan>p {
  font-size: 1.1rem !important;
  color: #9b9da2;
}

/deep/.el-tag {
  font-size: 1rem;
  margin-right: 1rem;
  border: 1px solid #0156ff;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.3rem;
}
</style>