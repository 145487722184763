/*
 * @Author: assad
 * @Date:   2022-01-17 20:43:13
 * @Last Modified by: you name
 * @Last Modified time: 2022-06-30 15:29:24
 */

import axios from 'axios';
import { aes, signStr, sha256 } from './xcrypto';
import { MessageBox, Message } from 'element-ui';
import { setItem, getItem } from '../utils/storage';
import { baseUrl, requestTimeOut, schoolId } from '../settings';

const ajax = axios.create({
  baseURL: baseUrl, // url前缀
  timeout: requestTimeOut, // 超时毫秒数
  withCredentials: false, // 携带认证信息cookie
});

let flag = true; //加个状态,解决token过期时刷新页面时重复提示
ajax.interceptors.response.use(
  (res) => {
    //获取错误信息
    const msg = res.data.msg;
    const code = res.data.code;
    if (code !== 0) {
      // Message.error({
      //     duration: 1000,
      //     message: msg
      // })
    } else {
    }
    return res.data;
  },
  (error) => {
    console.log('50,error', error);
    let { message } = error;
    if (message == 'Network Error') {
      message = '后端接口连接异常';
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时';
    }
    Message({
      message: message,
      type: 'error',
      duration: 1000,
    });
    return Promise.reject(error);
  }
);

/**
 * get方式请求，url传参
 * @param url 请求url
 * @param params 参数
 * @param level 0:无加密，1：参数加密，2: 签名； 默认0
 */
const get = (url, params, level) =>
  ajax(getConfig(url, 'get', params, true, level))
    .then((res) => successback(res))
    .catch((error) => errback(error));
/**
 * post方式请求 json方式传参
 * @param url 请求url
 * @param params 参数
 * @param level 0:无加密，1：参数加密，2: 签名+时间戳； 默认0
 */
const postJson = (url, params, level) =>
  ajax(getConfig(url, 'post', params, true, level))
    .then((res) => successback(res))
    .catch((error) => errback(error));
/**
 * post方式请求 表单传参
 * @param url 请求url
 * @param params 参数
 * @param level 0:无加密，1：参数加密，2: 签名+时间戳； 默认0
 */
const post = (url, params, level) =>
  ajax(getConfig(url, 'post', params, false, level))
    .then((res) => successback(res))
    .catch((error) => errback(error));
/**
 * delete方式请求 url传参
 * @param url 请求url
 * @param params 参数
 * @param level 0:无加密，1：参数加密，2: 签名+时间戳； 默认0
 */
const del = (url, params, level) =>
  ajax(getConfig(url, 'delete', params, true, level))
    .then((res) => successback(res))
    .catch((error) => errback(error));
/**
 * put方式请求 json传参
 * @param url 请求url
 * @param params 参数
 * @param level 0:无加密，1：参数加密，2: 签名+时间戳； 默认0
 */
const putJson = (url, params, level) =>
  ajax(getConfig(url, 'put', params, true, level))
    .then((res) => successback(res))
    .catch((error) => errback(error));
/**
 * put方式请求 表单传参
 * @param url 请求url
 * @param params 参数
 * @param level 0:无加密，1：参数加密，2: 签名+时间戳； 默认0
 */
const put = (url, params, level) =>
  ajax(getConfig(url, 'put', params, false, level))
    .then((res) => successback(res))
    .catch((error) => errback(error));

// 参数转换
const param2String = (data) => {
  if (typeof data === 'string') {
    return data;
  }
  let ret = '';
  for (let it in data) {
    let val = data[it];
    if (
      typeof val === 'object' && //
      (!(val instanceof Array) ||
        (val.length > 0 && typeof val[0] === 'object'))
    ) {
      val = JSON.stringify(val);
    }
    ret += it + '=' + encodeURIComponent(val) + '&';
  }
  if (ret.length > 0) {
    ret = ret.substring(0, ret.length - 1);
  }
  return ret;
};

// 错误回调函数
const errback = (error) => {
  if ('code' in error) {
    // 未登录
    if (error.code === -99) {
      sessionStorage.clear();
      window.location.href = '/';
      return;
    }
    return Promise.reject(error);
  }
  // 网络异常,或链接超时
  Message({
    message: error.msg,
    type: 'error',
  });
  return Promise.reject({ data: error.message });
};
// 成功回调函数
const successback = (res) => {
  // if (res.status === 200 && res.data.code !== 0) {
  //         Message({
  //             message: res.data.msg,
  //             type: 'error'
  //         });
  //     return Promise.reject(res.data);
  // }
  return res;
};

/**
 * @param url 请求url
 * @param method get,post,put,delete
 * @param isjson 是否json提交参数
 * @param params 参数
 * @param level 0:无加密，1：参数加密，2: 签名+时间戳； 默认0
 */
const getConfig = (url, method, params = {}, isjson = true, level = 2) => {
  let config_ = {
    url,
    method,
    // params, data,
    headers: {},
  };

  let userInformation = getItem('userInformation') || {}; //本地登录信息
  if (JSON.stringify(userInformation) != '{}') {
    let accountId = userInformation.accountId;
    let userToken = userInformation.userToken;
    config_.headers = {
      userToken: userToken,
      userId: accountId,
    };
  }
  // params.schoolId = schoolId;
  // 时间戳
  if (level === 1) {
    // 加密
    params = { encrypt: aes.en(JSON.stringify(params)) };
  } else if (level === 2) {
    // 签名
    // 签名串
    let signstr = signStr(params);
    params.sign = signstr;
  }
  // 表单提交参数
  if (!isjson) {
    config_.headers['Content-Type'] =
      'application/x-www-form-urlencoded;charset=UTF-8';
    config_.responseType = 'json';
    config_.transformRequest = [
      function (data) {
        return param2String(data);
      },
    ];
  }
  // 设置参数
  if (method in { get: true, delete: true }) {
    config_.params = params;
  } else if (method in { post: true, put: true }) {
    config_.data = params;
  }
  return config_;
};

// 统一方法输出口
export { ajax, get, postJson, post, del, putJson, put };
