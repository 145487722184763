<template>
  <div class="box">
    <!-- 头部部分 -->
    <div class="title">
      <div class="left">
        <!-- <img v-bind:src="centerConfig.logoUrl" @click="toHome()"  /> -->
        <img src="../assets/home/logo.png" @click="toHome()" alt="" />
      </div>
      <div class="right" @click="menuClick">
        <img :src="menuImgSrc[menuShow ? 1 : 0]" alt="" />
      </div>
    </div>
    <!-- 中心内容出口 -->
    <div style="margin-top: 2.8rem; background: #f4f8ff">
      <router-view />
    </div>
    <!-- 脚部 -->
    <div class="foot-box">
      <div class="foot">
        <div class="foot-titleText">学银通融(北京)教育科技有限公司</div>
        <div class="foot-info">
          <div class="foot-adress">
            <div class="address">地址：</div>
            <span>北京市海淀区高梁桥斜街59号1号院-中坤大厦1011</span>
          </div>
          <div class="foot-phone">电话：<a :href="'tel' + '400-0055-201'" style="color: #3b3d41">400-0055-201</a></div>
          <div class="foot-email">邮箱：<span>service@x-lingtong.com</span></div>
        </div>
        <div class="foot-code">
          <div style="text-align: center">
            <div class="foot-code-img">
              <img v-bind:src="centerConfig.wxmpQrcode" />
            </div>
            <div class="foot-code-text foot-code-text1">心灵通小程序</div>
          </div>
          <div style="text-align: center">
            <div class="foot-code-img">
              <img v-bind:src="centerConfig.wxQrcode" />
            </div>
            <div class="foot-code-text">心灵通公众号</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 导航弹框 -->
    <div class="fixed-nav" v-show="menuShow">
      <div class="bj" @click="navClose"></div>
      <div class="nav-content">
        <router-link to="/home">
          <div class="nav-item" @click="navClose">首页</div>
        </router-link>
        <router-link to="/move">
          <div class="nav-item" @click="navClose">动态</div>
        </router-link>
        <el-menu :default-active="$route.path" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" text-color router>
          <el-submenu index="1">
            <template slot="title" style="margin-bottom: 2.6rem">
              <span>方案</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/schemeHeight" style="margin-bottom: 2.6rem" @click="navClose">高等教育</el-menu-item>
              <el-menu-item index="/schemeTrade" style="margin-bottom: 2.6rem" @click="navClose">中等职业教育</el-menu-item>
              <el-menu-item index="/schemeBasics" style="margin-bottom: 2.6rem" @click="navClose">基础教育</el-menu-item>
              <el-menu-item index="/schemeTrain" style="margin-bottom: 2.6rem" @click="navClose">教师培训</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title" style="margin-bottom: 2.6rem">
              <span>产品</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/productSoft" style="margin-bottom: 2.6rem" @click="navClose">软件</el-menu-item>
              <el-menu-item index="/productHard" style="margin-bottom: 2.6rem" @click="navClose">硬件</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title" style="margin-bottom: 2.6rem">
              <span>内容</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/contentClass" style="margin-bottom: 2.6rem" @click="navClose">课程资源</el-menu-item>
              <el-menu-item index="/contentSelf" style="margin-bottom: 2.6rem" @click="navClose">心理微视频</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <router-link to="/aboutUs">
            <div class="nav-item" @click="navClose">关于我们</div>
          </router-link>
        </el-menu>
      </div>
    </div>
  </div>
</template>
<script>
import { post, get } from '../utils/xajax'
import { setItem } from '../utils/storage'
export default {
  data() {
    return {
      menuShow: false,
      cateList: [],
      centerConfig: {},
      menuImgSrc: [require('../assets/home/nav-icon.png'), require('../assets/home/close.png')],
    }
  },

  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    toHome() {
      this.$router.push('/home')
    },
    // 数据请求
    getCateList() {
      get('/index/common')
        .then((res) => {
          this.cateList = res.data.cateList
          this.centerConfig = res.data.centerConfig
          setItem('centerConfig', res.data.centerConfig)
          this.firendLinks = res.data.firendLinks
        })
        .catch((err) => {
          console.log(err)
        })
    },
    menuClick() {
      this.menuShow = !this.menuShow
    },
    navClose() {
      this.menuShow = false
    },
    menuClickNav(cateListItem) {
      console.log(cateListItem)
      this.$router.push('/cateListItem.url')
      console.log(cateListItem.url)
      this.navClose()
    },
    event: function (cateList) {
      console.log(cateList)
      return cateList.filter(function (cateListUrl) {
        return cateListUrl.url != 'testing'
      })
    },
    toHome() {
      this.$router.push({ path: '/home' })
    },
  },

  created() {},
  computed: {},
  mounted() {
    this.getCateList()
  },
}
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  background-color: #eee;
  overflow: hidden;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 1rem;
  line-height: 7.5rem;
  text-align: center;
  background-color: #39a9ed;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
.active-text {
  color: blue;
}
.box {
  background: #ffffff;
}
.title {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 5.2rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  background: #fff;
}

.title > .left {
  width: 7.1rem;
  line-height: 3.8rem;
  margin-left: 1.2rem;
}
.left > img {
  width: 6.5rem;
  padding: 1.3rem 0rem;
}
.right > img {
  padding-top: 2rem;
  width: 1.7rem;
  margin-right: 1.2rem;
}
// 轮播部分
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 1rem;
  line-height: 7.5rem;
  text-align: center;
  background-color: #39a9ed;
}
.foot-box {
  padding: 0 1.2rem;
  background: #f7f7f7;
}
.foot-titleText {
  padding-top: 1.6rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: #3b3d41;
}
.foot-info {
  padding-top: 1rem;
}
.foot-adress {
  display: flex;
}
.foot-adress,
.foot-phone,
.foot-email {
  font-size: 1.2rem;
  font-weight: 400;
  color: #3b3d41;
  margin-bottom: 0.6rem;
}
.foot-code {
  padding-top: 1.3rem;
  padding-bottom: 2.8rem;
  display: flex;
  justify-content: space-around;
}
.foot-code-img > img {
  width: 5rem;
  height: 5rem;
}
.foot-code-text {
  font-size: 1.2rem;
  height: 0.7rem;
  line-height: 0.7rem;
  padding-top: 1.2rem;
  color: #3b3d41;
  text-align: center;
}
.foot-code-text1 {
  margin-left: 0.5rem;
}
.fixed-nav {
  position: fixed;
  left: 0;
  top: 5.2rem;
  height: 100vh;
  width: 100vw;
  z-index: 100;
}
.fixed-nav .bj {
  background: rgba(59, 61, 65, 0.3);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
}
.fixed-nav .nav-content {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 102;
  background: #fff;
  width: 13rem;
  padding: 0.525rem 1.55rem;
  height: 100vh;
  //   overflow-y: auto;
  overflow-y: scroll;
}
.nav-content::-webkit-scrollbar {
  display: none;
}
.fixed-nav .nav-content .nav-item {
  // line-height: 1.75rem;
  // line-height: 3.6rem;
  margin-bottom: 2.6rem;
  width: 100%;
  color: #3b3d41;
  font-size: 1.6rem;
  font-weight: 400;
}
.address {
  white-space: nowrap;
}
/deep/.el-submenu__title {
  font-size: 1.6rem;
  line-height: 3rem;
  height: 5rem;
  // line-height: 2.6rem;
  padding: 0rem 0rem;
  padding-left: 0rem !important;
}
/deep/.el-menu {
  width: 112%;
}
/deep/.el-menu-item {
  font-size: 1.6rem;
  padding: 0rem 0rem;
  padding-left: 0rem !important;
}
/deep/.el-icon-arrow-down:before {
  font-size: 1rem;
}
/deep/.el-submenu .el-menu-item {
  padding: 0 2.25rem;
  min-width: 10rem;
  height: 4rem;
  line-height: 4rem;
}
/deep/ .el-icon-arrow-down:before {
  font-size: 2rem;
}
/deep/.el-submenu__icon-arrow {
  top: 25%;
}
/deep/.el-menu-item-group > ul {
  padding: 0rem 1.45rem;
}
/deep/.el-menu-item-group__title {
  padding: 0rem;
}
/deep/.el-menu-item.is-active {
  background-color: white !important;
  color: #4676f8;
}
/deep/.el-submenu__title:hover {
  background-color: white;
}
</style>
