<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/move/top-img.png" alt="" />
      <!-- <div class="topImg-headline">
              <div class="topImg-title">动态</div>
              <div class="topImg-text">从心出发 让生命充满力量</div>
          </div> -->
    </div>
    <div class="content-centre">
      <h4 class="newDetail-title">{{ artInfo.title }}</h4>
      <div class="newDetail-time">{{ artInfo.createdAt }}</div>
      <div class="newDetail-bottom"></div>
      <div class="indexNews-text indexNews-text2">
        <p class="box_img" v-html="artInfo.artBody"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post } from '../utils/xajax'
export default {
  data () {
    return {
      artInfo: {},
    }
  },
  methods: {
    getDetail () {
      let articleId = this.$route.params.artId
      get('/article/detail', { articleId: articleId }).then((res) => {
        this.artInfo = res.data
        // console.log(siteConfig);
        // document.title = this.artInfo.title + " - " + siteConfig.centerName;
      })
    },
  },
  updated () { //富文本图片设置宽度百分百
    let DomList = document.getElementsByClassName('box_img')[0].querySelectorAll('img')
    for (let i in DomList) {
      if (DomList[i].style) {
        DomList[i].style.width = '100%'
        DomList[i].style.height = 'auto'
      }
    }
  },
  mounted () {
    this.getDetail()
  },
}
</script>

<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0rem;
  padding: 0rem;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

.topImg>img {
  width: 100%;
  height: 7.1rem;
}

.topImg {
  display: flex;
  position: relative;
  margin-top: 2rem;
}

.topImg-headline {
  position: absolute;
  padding: 0rem 1.2rem;
}

.topImg-title {
  font-size: 1.8rem;
  font-weight: 600;
  font-family: AlibabaPuHuiTiB;
  color: #ffffff;
  line-height: 4.1rem;
}

.topImg-text {
  font-size: 1.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #ffffff;
  letter-spacing: 0.2rem;
}

.content-centre {
  padding: 0rem 1.2rem;
  //   min-height: 37.5rem /* 600/16 */;
  min-height: 50vh;
}

.newDetail-title {
  font-size: 1.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  line-height: 2rem;
  margin: 2rem 0rem 0.7rem;
}

.newDetail-time {
  height: 1.5rem;
  font-size: 1.1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9b9da2;
  line-height: 1.5rem;
  margin-bottom: 1.2rem;
}

.newDetail-bottom {
  width: 100%;
  height: 0.05rem;
  background: #e5eaf1;
  margin-bottom: 2rem;
}
</style>
<style>
h1>span {
  font-size: 2rem !important;
}

p {
  font-size: 1.4rem !important;
  line-height: 2.4rem;
}

p>span {
  font-size: 1.4rem !important;
}

.box_img>img {
  max-width: 100% !important;
  /* transform: scale(0.5);  */
}

.box_img>p>img {
  max-width: 100% !important;
  /* transform: scale(0.5);  */
}

.box_img>p>span>img {
  max-width: 100% !important;
  /* transform: scale(0.5);  */
}

.box_img>p>span>span>img {
  width: 100%;
  max-width: 100% !important;
  /* transform: scale(0.5);  */
}

.box_img>p>strong>img {
  max-width: 100% !important;
  /* transform: scale(0.5);  */
}

p>img {
  max-width: 100% !important;
}</style>